import React, { Component } from "react";
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Form,
  FormGroup,
  Input,
  Label,
  Alert,
  Modal,
  Container,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import withRouter from "../../../components/Common/withRouter";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import TableLoader from "../../../components/Common/TableLoader";
import "../../../../src/App.css";
export class Users extends Component {
  constructor(props) {
    super(props);
    this.gridRef = React.createRef();
    this.state = {
      openRightPane: false,
      
      coCode: "",
      name: "",
      designation: "",
      role: "",
      email: "",
      password: "",
      confirmPassword: "",
      photo: "",
      city: "",
      country: "",
      phone: "",
      gender: "",

      ComapnyCode: [],
      orgUsersData: [],
      userEditPane: [],
      openEditPane: false,
      rolesData: [],
      validation: {
        name: "",
        designation: "",
        role: "",
        coCode: "",
        email: "",
        password: "",
        confirmPassword: "",
        photo: "",
        city: "",
        country: "",
        phone: "",
        gender: "",
      },

      editcoCode: "",
      editname: "",
      editdesignation: "",
      editrole: "",
      editemail: "",
      editpassword: "",
      editconfirmPassword: "",
      editphoto: "",
      editcity: "",
      editcountry: "",
      editphone: "",
      editgender: "",
      deleteModal: false,
      deleteItem: {},
      deleteIndex: null,

      loading: true,
      orgName: "",
      loaderRows: [1, 2, 3, 4, 5, 6, 7],
      loaderCol: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
    };
  }

  componentDidMount() {
    axios
      .get("https://api-news.nebrascorp.com/api/get-organization")
      .then((res) => {
        console.log(res.data.data, "sakdj389ur2092r");
        if (res.data.data) {
          this.setState({
            ComapnyCode: res.data.data,
          });

          this.stateData(res.data.data);
        }
      });
  }
  submitPagesForm = () => {
    const {
      name,
      designation,
      role,
      coCode,
      email,
      password,
      confirmPassword,
      photo,
      city,
      country,
      phone,
      gender,
    } = this.state;

    let isValid = true;
    const updatedvalidation = {
      name: "",
      coCode: "",
      designation: "",
      role: "",
      email: "",
      password: "",
      confirmPassword: "",
      photo: "",
      city: "",
      country: "",
      phone: "",
      gender: "",
    };

    if (!name || !name.trim()) {
      updatedvalidation["name"] = "Name is Required*";
      isValid = false;
    }
    if (!coCode || !coCode.trim()) {
      updatedvalidation["coCode"] = "Company Code is Required*";
      isValid = false;
    }
    if (!designation || !designation.trim()) {
      updatedvalidation["designation"] = "Designation is Required*";
      isValid = false;
    }
    if (!city || !city.trim()) {
      updatedvalidation["city"] = "City is Required*";
      isValid = false;
    }
    if (!gender || !gender.trim()) {
      updatedvalidation["gender"] = "Gender is Required*";
      isValid = false;
    }
    if (!country || !country.trim()) {
      updatedvalidation["country"] = "Country is Required*";
      isValid = false;
    }
    if (!phone || !phone.trim()) {
      updatedvalidation["phone"] = "Phone is Required*";
      isValid = false;
    }
    if (!role || !role.trim()) {
      updatedvalidation["role"] = "Role is Required*";
      isValid = false;
    }
    if (!email || !email.trim()) {
      updatedvalidation["email"] = "Email is Required*";
      isValid = false;
    }
    if (!password || !password.trim()) {
      updatedvalidation["password"] = "Password is Required*";
      isValid = false;
    }
    if (!confirmPassword || !confirmPassword.trim()) {
      updatedvalidation["confirmPassword"] = "Confirm Password is Required*";
      isValid = false;
    }
    if (!photo) {
      updatedvalidation["photo"] = "Photo is Required*";
      isValid = false;
    }

    if (!isValid) {
      this.setState({ validation: updatedvalidation });
      console.log(updatedvalidation, "abcdefghikl");
      return;
    } else {
      const uuid = Date.now().toString(36) + Math.random().toString(36).substr(2);
      const Obj = {
        name: name,
        uniqueUserId: uuid,
        designation: designation,
        role: role,
        coCode: coCode,
        email: email,
        password: password,
        confirmPassword: confirmPassword,
        photo: photo,
        city: city,
        country: country,
        gender: gender,
        phone: phone,
      };
      console.log(Obj, "objectUser");
      axios.post("https://api-news.nebrascorp.com/api/create-user", { Obj });
      window.location.reload();
    }
  };
  stateData = (name) => {
    console.log(name, "asdioajsd92390jwiq");

    // axios.get("https://api-news.nebrascorp.com/api/form-submission",{
    //   params:{
    //     collection : data.FormName.replaceAll(" ","")
    //   }
    // }).then((res)=>{
    //   console.log(res,'aslidu3iwour98uriowasndk')
    //   this.setState({
    //     name:name,
    //     gridData:res.data.data,
    //     schemaData: this.props.router.location.state.data
    //   })
    // })
  };
  onGridReady(params) {
    console.log(params, "askdajsld");
    this.gridRef = params;
    console.log(this.gridRef);
  }

  getUsersOfOrg = (value) => {
    console.log(value, "shahzaubbbbdataa")

    this.setState({
      orgName: value.OrganizationName.replaceAll(" ", ""),
    });

    const Obj = {
      name: value.OrganizationName,
    };

    axios
      .post("https://api-news.nebrascorp.com/api/get-usersOfOrg", { Obj })
      .then((res) => {
        console.log(res, "asidijasilkdhaksjdhaiu");
        if (res.data.data) {
          this.setState({
            orgUsersData: res.data.data,
            loading: false,
          });
        }
      });
  };
  imageSetUpFunction = (value) => {
    console.log(value[0], "ajskdhaskjdhih23uoweid");

    var datas = new FormData();
    datas.append(`OrganizationImage`, value[0]);
    axios({
      method: "post",
      url: "https://api-news.nebrascorp.com/api/image-url-path",
      data: datas,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        console.log(res.data, "asdiu2983ue9disajkc");
        this.setState({
          photo: res.data.OrganizationImage,
        });
      })
      .catch((err) => console.log(err, "errpr"));
  };
  editImageSetUpFunction = (value) => {
    console.log(value[0], "ajskdhaskjdhih23uoweid");

    var datas = new FormData();
    datas.append(`OrganizationImage`, value[0]);
    axios({
      method: "post",
      url: "https://api-news.nebrascorp.com/api/image-url-path",
      data: datas,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        console.log(res.data, "asdiu2983ue9disajkc");
        this.setState({
          editphoto: res.data.OrganizationImage,
        });
      })
      .catch((err) => console.log(err, "errpr"));
  };
  submitEditForm = () => {
    const {
      editcoCode,
      editname,
      editdesignation,
      editrole,
      editemail,
      editpassword,
      editconfirmPassword,
      editphoto,
      editcity,
      editcountry,
      editphone,
      editgender,
      userEditPane,
    } = this.state;

    console.log(editrole)

    const Obj = {
      uniqueUserId: userEditPane.uniqueUserId,
      name: editname && editname !== "" ? editname : userEditPane.name,
      designation:
        editdesignation && editdesignation !== ""
          ? editdesignation
          : userEditPane.designation,
      role: editrole && editrole !== "" ? editrole : userEditPane.role,
      coCode:
        editcoCode && editcoCode !== "" ? editcoCode : userEditPane.coCode,
      email: editemail && editemail !== "" ? editemail : userEditPane.email,
      password:
        editpassword && editpassword !== ""
          ? editpassword
          : userEditPane.password,
      confirmPassword:
        editconfirmPassword && editconfirmPassword !== ""
          ? editconfirmPassword
          : userEditPane.confirmPassword,
      photo: editphoto && editphoto !== "" ? editphoto : userEditPane.photo,
      city: editcity && editcity !== "" ? editcity : userEditPane.city,
      country:
        editcountry && editcountry !== "" ? editcountry : userEditPane.country,
      gender:
        editgender && editgender !== "" ? editgender : userEditPane.gender,
      phone: editphone && editphone !== "" ? editphone : userEditPane.phone,
    };


    console.log(Obj ,"obejcteditfinalize")

    // axios.post("https://api-news.nebrascorp.com/api/create-user", { Obj }).then(res =>console.log(res, "djksdhsjkdskdskdj"));

    // window.location.reload();
  };

  handleDeleteOFUsers = (data, index) => {
    const { orgUsersData, orgName } = this.state;

    // axios.post("https://api-news.nebrascorp.com/api/delete-user",{
    //   uuid: data.uniqueUserId,
    //   organizationName:orgName
    // })
  };
  getRoles = (value) => {
    this.setState({ coCode: value });

    console.log(value, "orgnNameValue")

    // this api doesnt ssems to be wrking will check later
    axios
      // .get(`https://api-news.nebrascorp.com/api/get-roles?orgName=${value}`)
      .get(`http://localhost:3004/api/get-roles?orgName=${value}`)

      .then((res) => {

        console.log(res.data.status, "hfwekfkejgfewfhwiofa")
        
        this.setState({
          rolesData: res.data.data,
        });
      });
  };
  render() {
    console.log("shahzaibbbbbbb" , this.state.rolesData)
    const { ComapnyCode, orgUsersData, rolesData, userEditPane, deleteModal } =

      this.state;
    console.log(ComapnyCode, "asdkadhq93h98iow");
    var rowData = [];
    var columnDefs = [
      { headerName: "Serial No", field: "SerialNo" },
      { headerName: "Id", field: "Id" },
      { headerName: "Organization Name", field: "OrganizationName" },
    ];


    let userDataS = localStorage.getItem('authUser');
    var userChecks = userDataS!==null ? JSON.parse(userDataS):null;

    var settingRowData =
      ComapnyCode &&
      ComapnyCode.length > 0 
      &&
      ComapnyCode.map((item) => {
        Object.keys(item).forEach((items, index) => {
          if(item[items].shortName === userChecks?.companyColName){

          rowData.push({
            SerialNo: index,
            Id: index + 1,
            OrganizationName: item[items].shortName,
          });
        }
        });
      });

    //  var rowData  = ComapnyCode && ComapnyCode.length>0 && ComapnyCode.map((item)=>{

    //  Object.keys(item).map((items,index)=>{
    //     return {
    //         SerialNo: index,
    //         Id: index+1,
    //         OrganizationName: item[items].name

    //     }
    //  })

    // })
    console.log(orgUsersData, "ajksdhiweuoqiue90213");
    // const tableDataSet = orgUsersData!== undefined && Object.keys(orgUsersData).map((item)=>)
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Users" breadcrumbItem="User" />

            <Button
              color="primary"
              style={{ float: "right" }}
              onClick={() => {this.setState({ openRightPane: true }); this.getRoles(userChecks.companyColName)}}
            >
              Create User
            </Button>
            <br />
            <br />
            <br />

            <Row>
              <Col xl={12}>
                <Card>
                  <CardHeader>
                    <h4 className="card-title">
                      Select Organization To Check there Users.
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <div
                      className="ag-theme-balham"
                      style={{ height: 300, width: "40%", marginLeft: "25%" }}
                    >
                      <AgGridReact
                        ref={this.gridRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        overlayLoadingTemplate={
                          '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                        }
                        overlayNoRowsTemplate="<span> Please Wait while Loading</span>"
                        rowHeight={55}
                        style={{
                          width: "100%",
                          height: "100%",
                          overflow: "hidden",
                        }}
                        rowSelection={"single"}
                        onGridReady={this.onGridReady}
                        onCellClicked={(e) => {
                          this.getUsersOfOrg(e.node.data);
                        }}
                      ></AgGridReact>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {
              // orgUsersData && orgUsersData.length>0 &&

              <Row>
                <Col xl={12}>
                  <Card>
                    <CardHeader>
                      {/* <h4 className='card-title'>Select Organization To Check there Users.</h4> */}
                    </CardHeader>
                    <CardBody></CardBody>

                    <div className="table-responsive">
                      {!this.state.loading ? (
                        <Table className="table mb-0">
                          <thead className="table-light">
                            <tr>
                              <th>S.No</th>

                              {orgUsersData !== undefined &&
                                Object.keys(orgUsersData).map((item, index) => {
                                  console.log(index, "checking indexes !!");

                                  return Object.keys(orgUsersData[item]).map(
                                    (items) => {
                                      if (
                                        items !== "password" &&
                                        items !== "confirmPassword" &&
                                        items !== "uniqueUserId"
                                      ) {
                                        return <th key={index}>{items}</th>;
                                      }
                                    }
                                  );
                                })[0]}

                              <th>Edit</th>
                              <th>Delete</th>
                            </tr>
                          </thead>

                          <tbody>
                            {orgUsersData !== undefined &&
                              Object.keys(orgUsersData).map((item, index) => (
                                <>
                                  <tr>
                                    <th scope="row">{index + 1}</th>

                                    {Object.keys(orgUsersData[item]).map(
                                      (items, indexs) => {
                                        if (
                                          items !== "password" &&
                                          items !== "confirmPassword" &&
                                          items !== "uniqueUserId"
                                        ) {
                                          return (
                                            <td key={indexs}>
                                              {orgUsersData[item][items]}
                                            </td>
                                          );
                                        }
                                      }
                                    )}

                                    <td
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.setState({
                                          userEditPane: orgUsersData[item],
                                          openEditPane: true,
                                        })
                                      }
                                    >
                                      {" "}
                                      <EditOutlined />{" "}
                                    </td>
                                    <td
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.setState({
                                          deleteModal: true,
                                          deleteItem: orgUsersData[item],
                                          deleteIndex: index,
                                        })
                                      }
                                    >
                                      {" "}
                                      <DeleteOutlined />{" "}
                                    </td>
                                  </tr>
                                </>
                              ))}
                          </tbody>
                        </Table>
                      ) : (
                        <TableLoader
                          rows={this.state.loaderRows}
                          col={this.state.loaderCol}
                        />
                      )}
                    </div>
                  </Card>
                </Col>
              </Row>
            }
          </div>

          <Offcanvas
            fade={false}
            isOpen={this.state.openRightPane}
            style={{ width: "80%" }}
            scrollable
            direction="end"
            toggle={() => this.setState({ openRightPane: false })}
          >
            <OffcanvasHeader
              toggle={() => this.setState({ openRightPane: false })}
            >
              {/* User  */}
            </OffcanvasHeader>

            <OffcanvasBody>
              <Card>
                <CardBody>
                  <h4>User</h4>
                  <br />

                  <Container>
                    <Form>
                      <Row>
                        {/* <Col
                    md={6}
                    xs={{
                      offset: 2
                     }}
                  > */}
                        <FormGroup row>
                          <Label
                            sm={3}
                            style={{
                              textAlign: "right",
                              textTransform: "capitalize",
                            }}
                            for="examplePassword"
                          >
                            User Name
                          </Label>
                          <Col sm={6}>
                            <Input
                              id="examplePassword"
                              name="id"
                              placeholder="Enter User Name"
                              type="text"
                              onChange={(e) =>
                                this.setState({ name: e.target.value })
                              }
                            />
                            {this.state.validation["name"] && (
                              <p style={{ color: "red", fontSize: "12px" }}>
                                {this.state.validation["name"]}
                              </p>
                            )}
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={3}
                            style={{
                              textAlign: "right",
                              textTransform: "capitalize",
                            }}
                            for="examplePassword"
                          >
                            Email
                          </Label>
                          <Col sm={6}>
                            <Input
                              id="examplePassword"
                              name="id"
                              placeholder="Enter Email"
                              type="email"
                              onChange={(e) =>
                                this.setState({ email: e.target.value })
                              }
                            />
                            {this.state.validation["email"] && (
                              <p style={{ color: "red", fontSize: "12px" }}>
                                {this.state.validation["email"]}
                              </p>
                            )}
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={3}
                            style={{
                              textAlign: "right",
                              textTransform: "capitalize",
                            }}
                            for="examplePassword"
                          >
                            Password
                          </Label>
                          <Col sm={6}>
                            <Input
                              id="examplePassword"
                              name="id"
                              placeholder="Enter Password"
                              type="password"
                              onChange={(e) =>
                                this.setState({ password: e.target.value })
                              }
                            />
                            {this.state.validation["password"] && (
                              <p style={{ color: "red", fontSize: "12px" }}>
                                {this.state.validation["password"]}
                              </p>
                            )}
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={3}
                            style={{
                              textAlign: "right",
                              textTransform: "capitalize",
                            }}
                            for="examplePassword"
                          >
                            Confirm Password
                          </Label>
                          <Col sm={6}>
                            <Input
                              id="examplePassword"
                              name="id"
                              placeholder="Confirm Password"
                              type="password"
                              onChange={(e) =>
                                this.setState({
                                  confirmPassword: e.target.value,
                                })
                              }
                            />
                            {this.state.validation["confirmPassword"] && (
                              <p style={{ color: "red", fontSize: "12px" }}>
                                {this.state.validation["confirmPassword"]}
                              </p>
                            )}
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={3}
                            style={{
                              textAlign: "right",
                              textTransform: "capitalize",
                            }}
                            for="examplePassword"
                          >
                            Company Code
                          </Label>

                          <Col sm={6}>
                            <Input
                              id="exampleSelect"
                              name="select"
                              type="select"
                              // placeholder='Enter Role Of User'
                              // onChange={(e) => this.getRoles(e.target.value)}
                            >
                              <option disabled selected hidden>
                                Select Company
                              </option>
                              {
                                // ComapnyCode && Object.keys(ComapnyCode).map((items ,index )=>(
                                //   <option value={`${ComapnyCode[items].name.replaceAll(" ","")}`} key={index} >{ComapnyCode[items].name}</option>
                                // ))
                                ComapnyCode &&
                                  ComapnyCode.length > 0 &&
                                  ComapnyCode.map((item, index) => {
                                    return Object.keys(item).map((items) => {
                                      console.log(item, "jkhdiewuhfefhe,", items)
                                      if(item[items].shortName === userChecks.companyColName)
                                      return (
                                        <option
                                          value={`${item[items].name.replaceAll(
                                            " ",
                                            ""
                                          )}`}
                                          key={index}
                                        >
                                          {item[items].name}
                                        </option>
                                      );
                                    });
                                  })
                              }
                            </Input>
                            {this.state.validation["coCode"] && (
                              <p style={{ color: "red", fontSize: "12px" }}>
                                {this.state.validation["coCode"]}
                              </p>
                            )}
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={3}
                            style={{
                              textAlign: "right",
                              textTransform: "capitalize",
                            }}
                            for="examplePassword"
                          >
                            Select Role
                          </Label>
                          <Col sm={6}>
                            <Input
                              id="exampleSelect"
                              name="select"
                              type="select"
                              // placeholder='Enter Role Of User'
                              
                              // onChange={(e) => this.getRoles(e.target.value)}

                            >
                              <option disabled selected hidden>
                                Select Any Role
                              </option>

                              {/* {
                                userChecks.companyColName === "PIVOT" 
                                ?
                                <>
                              <option value="UAT">UAT</option>
                              <option value="BD">BD</option>
                                </> 
                                :
                                userChecks.companyColName === "Nebras" 
                                ?
                                <>
                              <option value="ARD">ARD</option>
                              <option value="UAT">UAT</option>
                                </> 

                                :
                                userChecks.companyColName === "ALM" 
                                &&
                                <>
                              <option value="ARD">ARD</option>
                              <option value="CMH">CMH</option>
                                </> 


                              } */}

                              {Object.keys(rolesData).map((items, index) => (
                                <option
                                  key={index}
                                  value={rolesData[items].code}
                                >
                                  {rolesData[items].code}
                                </option>
                              ))}


                            </Input>
                            {this.state.validation["role"] && (
                              <p style={{ color: "red", fontSize: "12px" }}>
                                {this.state.validation["role"]}
                              </p>
                            )}
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={3}
                            style={{
                              textAlign: "right",
                              textTransform: "capitalize",
                            }}
                            for="examplePassword"
                          >
                            Select Gender
                          </Label>
                          <Col sm={6}>
                            <Input
                              id="exampleSelect"
                              name="select"
                              type="select"
                              // placeholder='Enter Role Of User'
                              onChange={(e) =>
                                this.setState({ gender: e.target.value })
                              }
                            >
                              <option disabled selected hidden>
                                Select Any Gender
                              </option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                            </Input>
                            {this.state.validation["gender"] && (
                              <p style={{ color: "red", fontSize: "12px" }}>
                                {this.state.validation["gender"]}
                              </p>
                            )}
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={3}
                            style={{
                              textAlign: "right",
                              textTransform: "capitalize",
                            }}
                            for="examplePassword"
                          >
                            Phone
                          </Label>
                          <Col sm={6}>
                            <Input
                              id="examplePassword"
                              name="id"
                              placeholder="Enter Phone"
                              type="number"
                              onChange={(e) =>
                                this.setState({ phone: e.target.value })
                              }
                            />
                            {this.state.validation["phone"] && (
                              <p style={{ color: "red", fontSize: "12px" }}>
                                {this.state.validation["phone"]}
                              </p>
                            )}
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={3}
                            style={{
                              textAlign: "right",
                              textTransform: "capitalize",
                            }}
                            for="examplePassword"
                          >
                            Select City
                          </Label>
                          <Col sm={6}>
                            <Input
                              id="exampleSelect"
                              name="select"
                              type="select"
                              // placeholder='Enter Role Of User'
                              onChange={(e) =>
                                this.setState({ city: e.target.value })
                              }
                            >
                              <option disabled selected hidden>
                                Select Any City
                              </option>
                              <option value="Dubai">Dubai</option>
                              <option value="Sharjah">Sharjah</option>
                              <option value="Abu Dhabi">Abu Dhabi</option>
                            </Input>
                            {this.state.validation["city"] && (
                              <p style={{ color: "red", fontSize: "12px" }}>
                                {this.state.validation["city"]}
                              </p>
                            )}
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={3}
                            style={{
                              textAlign: "right",
                              textTransform: "capitalize",
                            }}
                            for="examplePassword"
                          >
                            Select Country
                          </Label>
                          <Col sm={6}>
                            <Input
                              id="exampleSelect"
                              name="select"
                              type="select"
                              // placeholder='Enter Role Of User'
                              onChange={(e) =>
                                this.setState({ country: e.target.value })
                              }
                            >
                              <option disabled selected hidden>
                                Select Any Role
                              </option>
                              <option value="UAE">UAE</option>
                              <option value="GCC Countries">
                                GCC Countries
                              </option>
                              <option value="Others">Others</option>
                            </Input>
                            {this.state.validation["country"] && (
                              <p style={{ color: "red", fontSize: "12px" }}>
                                {this.state.validation["country"]}
                              </p>
                            )}
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={3}
                            style={{
                              textAlign: "right",
                              textTransform: "capitalize",
                            }}
                            for="examplePassword"
                          >
                            Designation
                          </Label>
                          <Col sm={6}>
                            <Input
                              id="examplePassword"
                              name="Description"
                              placeholder="Enter Designation"
                              type="text"
                              onChange={(e) =>
                                this.setState({ designation: e.target.value })
                              }
                            />
                            {this.state.validation["designation"] && (
                              <p style={{ color: "red", fontSize: "12px" }}>
                                {this.state.validation["designation"]}
                              </p>
                            )}
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={3}
                            style={{
                              textAlign: "right",
                              textTransform: "capitalize",
                            }}
                            for="examplePassword"
                          >
                            Photo
                          </Label>
                          <Col sm={6}>
                            <Input
                              id="examplePassword"
                              name="id"
                              // placeholder='Enter User Name'
                              type="file"
                              onChange={(e) =>
                                this.imageSetUpFunction(e.target.files)
                              }
                            />
                            {this.state.validation["photo"] && (
                              <p style={{ color: "red", fontSize: "12px" }}>
                                {this.state.validation["photo"]}
                              </p>
                            )}
                          </Col>
                        </FormGroup>

                        <Col sm={9} style={{ textAlign: "right" }}>
                          <Button
                            color="success"
                            // style={{ float: 'right' }}

                            style={{ marginRight: 10 }}
                            onClick={() => this.submitPagesForm()}
                          >
                            Submit Form
                          </Button>
                        </Col>
                        {/* </Col> */}
                      </Row>
                    </Form>
                  </Container>
                </CardBody>
              </Card>
            </OffcanvasBody>
          </Offcanvas>

          <Offcanvas
            fade={false}
            isOpen={this.state.openEditPane}
            style={{ width: "80%" }}
            scrollable
            direction="end"
            toggle={() => this.setState({ openEditPane: false })}
          >
            <OffcanvasHeader
              toggle={() => this.setState({ openEditPane: false })}
            >
              {/* User Edit */}
            </OffcanvasHeader>

            <OffcanvasBody>
              <Card>
                <CardBody>
                  <h4>User Edit</h4>
                  <br />

                  <Container>
                    <Form>
                      <Row>
                        {/* <Col
                    md={6}
                    xs={{
                      offset: 2
                     }}
                  > */}
                        <FormGroup row>
                          <Label
                            sm={3}
                            style={{
                              textTransform: "capitalize",
                              textAlign: "right",
                            }}
                            for="examplePassword"
                          >
                            User Name
                          </Label>
                          <Col sm={6}>
                            <Input
                              id="examplePassword"
                              name="id"
                              placeholder={userEditPane.name}
                              type="text"
                              onChange={(e) =>
                                this.setState({ editname: e.target.value })
                              }
                            />
                            {this.state.validation["name"] && (
                              <p style={{ color: "red", fontSize: "12px" }}>
                                {this.state.validation["name"]}
                              </p>
                            )}
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={3}
                            style={{
                              textTransform: "capitalize",
                              textAlign: "right",
                            }}
                            for="examplePassword"
                          >
                            Email
                          </Label>
                          <Col sm={6}>
                            <Input
                              id="examplePassword"
                              name="id"
                              placeholder={userEditPane.email}
                              type="email"
                              onChange={(e) =>
                                this.setState({ editemail: e.target.value })
                              }
                            />
                            {this.state.validation["email"] && (
                              <p style={{ color: "red", fontSize: "12px" }}>
                                {this.state.validation["email"]}
                              </p>
                            )}
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={3}
                            style={{
                              textTransform: "capitalize",
                              textAlign: "right",
                            }}
                            for="examplePassword"
                          >
                            Password
                          </Label>
                          <Col sm={6}>
                            <Input
                              id="examplePassword"
                              name="id"
                              placeholder={"*******"}
                              type="password"
                              onChange={(e) =>
                                this.setState({ editpassword: e.target.value })
                              }
                            />
                            {this.state.validation["password"] && (
                              <p style={{ color: "red", fontSize: "12px" }}>
                                {this.state.validation["password"]}
                              </p>
                            )}
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={3}
                            style={{
                              textTransform: "capitalize",
                              textAlign: "right",
                            }}
                            for="examplePassword"
                          >
                            Confirm Password
                          </Label>
                          <Col sm={6}>
                            <Input
                              id="examplePassword"
                              name="id"
                              placeholder={"*******"}
                              type="password"
                              onChange={(e) =>
                                this.setState({
                                  editconfirmPassword: e.target.value,
                                })
                              }
                            />
                            {this.state.validation["confirmPassword"] && (
                              <p style={{ color: "red", fontSize: "12px" }}>
                                {this.state.validation["confirmPassword"]}
                              </p>
                            )}
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={3}
                            style={{
                              textTransform: "capitalize",
                              textAlign: "right",
                            }}
                            for="examplePassword"
                          >
                            Select Role
                          </Label>
                          <Col sm={6}>
                            <Input
                              id="exampleSelect"
                              name="select"
                              type="select"
                              placeholder={userEditPane.role}
                              onChange={(e) =>
                                this.setState({ editrole: e.target.value })
                              }
                            >
                              <option disabled selected hidden>
                              {userEditPane.role}                              
                              </option>

                              {/* {Object.keys(rolesData).map((items, index) => (
                                <option
                                  key={index}
                                  value={rolesData[items].code}
                                >
                                  {rolesData[items].name}
                                </option>
                              ))} */}

                              {
                                userChecks.companyColName === "PIVOT" 
                                ?
                                <>
                              <option value="UAT">UAT</option>
                              <option value="BD">BD</option>
                                </> 
                                :
                                userChecks.companyColName === "Nebras" 
                                ?
                                <>
                              <option value="ARD">ARD</option>
                              <option value="UAT">UAT</option>
                                </> 

                                :
                                userChecks.companyColName === "ALM" 
                                &&
                                <>
                              <option value="ARD">ARD</option>
                              <option value="CMH">CMH</option>
                                </> 
                              }

                            </Input>
                            {this.state.validation["role"] && (
                              <p style={{ color: "red", fontSize: "12px" }}>
                                {this.state.validation["role"]}
                              </p>
                            )}
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={3}
                            style={{
                              textTransform: "capitalize",
                              textAlign: "right",
                            }}
                            for="examplePassword"
                          >
                            Select Gender
                          </Label>
                          <Col sm={6}>
                            <Input
                              id="exampleSelect"
                              name="select"
                              type="select"
                              placeholder={userEditPane.gender}
                              onChange={(e) =>
                                this.setState({ editgender: e.target.value })
                              }
                            >
                              <option disabled selected hidden>
                                Select Any Gender
                              </option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                            </Input>
                            {this.state.validation["gender"] && (
                              <p style={{ color: "red", fontSize: "12px" }}>
                                {this.state.validation["gender"]}
                              </p>
                            )}
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={3}
                            style={{
                              textTransform: "capitalize",
                              textAlign: "right",
                            }}
                            for="examplePassword"
                          >
                            Phone
                          </Label>
                          <Col sm={6}>
                            <Input
                              id="examplePassword"
                              name="id"
                              placeholder={userEditPane.phone}
                              type="number"
                              onChange={(e) =>
                                this.setState({ editphone: e.target.value })
                              }
                            />
                            {this.state.validation["phone"] && (
                              <p style={{ color: "red", fontSize: "12px" }}>
                                {this.state.validation["phone"]}
                              </p>
                            )}
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={3}
                            style={{
                              textTransform: "capitalize",
                              textAlign: "right",
                            }}
                            for="examplePassword"
                          >
                            Select City
                          </Label>
                          <Col sm={6}>
                            <Input
                              id="exampleSelect"
                              name="select"
                              type="select"
                              placeholder={userEditPane.city}
                              onChange={(e) =>
                                this.setState({ editcity: e.target.value })
                              }
                            >
                              <option disabled selected hidden>
                                Select Any City
                              </option>
                              <option value="Dubai">Dubai</option>
                              <option value="Sharjah">Sharjah</option>
                              <option value="Abu Dhabi">Abu Dhabi</option>
                            </Input>
                            {this.state.validation["city"] && (
                              <p style={{ color: "red", fontSize: "12px" }}>
                                {this.state.validation["city"]}
                              </p>
                            )}
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={3}
                            style={{
                              textTransform: "capitalize",
                              textAlign: "right",
                            }}
                            for="examplePassword"
                          >
                            Select Country
                          </Label>
                          <Col sm={6}>
                            <Input
                              id="exampleSelect"
                              name="select"
                              type="select"
                              placeholder={userEditPane.country}
                              onChange={(e) =>
                                this.setState({ editcountry: e.target.value })
                              }
                            >
                              <option disabled selected hidden>
                                Select Any Country
                              </option>
                              <option value="UAE">UAE</option>
                              <option value="GCC Countries">
                                GCC Countries
                              </option>
                              <option value="Others">Others</option>
                            </Input>
                            {this.state.validation["country"] && (
                              <p style={{ color: "red", fontSize: "12px" }}>
                                {this.state.validation["country"]}
                              </p>
                            )}
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={3}
                            style={{
                              textTransform: "capitalize",
                              textAlign: "right",
                            }}
                            for="examplePassword"
                          >
                            Designation
                          </Label>
                          <Col sm={6}>
                            <Input
                              id="examplePassword"
                              name="Description"
                              placeholder={userEditPane.designation}
                              type="text"
                              onChange={(e) =>
                                this.setState({
                                  editdesignation: e.target.value,
                                })
                              }
                            />
                            {this.state.validation["designation"] && (
                              <p style={{ color: "red", fontSize: "12px" }}>
                                {this.state.validation["designation"]}
                              </p>
                            )}
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={3}
                            style={{
                              textTransform: "capitalize",
                              textAlign: "right",
                            }}
                            for="examplePassword"
                          >
                            Company Code
                          </Label>
                          <Col sm={6}>
                            {/* below extraa dditional code /////////////
                            <Input
                        id='examplePassword'
                        name='Url'
                        placeholder='Enter Url'
                        type='text'
                        onChange={e => this.setState({coCode:e.target.value})}
                      />

                          above extraa dditional code ///////////// */}


                            <Input
                              id="exampleSelect"
                              name="select"
                              type="select"
                              placeholder={userEditPane.coCode}
                              onChange={(e) =>
                                this.setState({ editcoCode: e.target.value })
                              }
                            >
                              <option disabled selected hidden>
                                Select Any Company Code
                              </option>
                              {ComapnyCode &&
                                ComapnyCode.length > 0 &&
                                ComapnyCode.map((item, index) => {
                                  return Object.keys(item).map((items) => {
                                    if(item[items].shortName === userChecks.companyColName)
                                    return (
                                      <option
                                        value={`${item[items].name.replaceAll(
                                          " ",
                                          ""
                                        )}`}
                                        key={index}
                                      >
                                        {item[items].name}
                                      </option>
                                    );
                                  });
                                })}
                            </Input>
                            {this.state.validation["coCode"] && (
                              <p style={{ color: "red", fontSize: "12px" }}>
                                {this.state.validation["coCode"]}
                              </p>
                            )}
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={3}
                            style={{
                              textTransform: "capitalize",
                              textAlign: "right",
                            }}
                            for="examplePassword"
                          >
                            Photo
                          </Label>
                          <Col sm={1}>
                            <img
                              src={`https://api-news.nebrascorp.com/pivot-media/${userEditPane.photo}`}
                              height={40}
                              width={40}
                            />
                          </Col>
                          <Col sm={5}>
                            {/* <div>
                        <img src={`https://api-news.nebrascorp.com/pivot-media/${userEditPane.photo}`} width={150}/>
                      </div> */}
                            <Input
                              id="examplePassword"
                              name="id"
                              // placeholder='Enter User Name'
                              type="file"
                              onChange={(e) =>
                                this.editImageSetUpFunction(e.target.files)
                              }
                            />
                            {this.state.validation["photo"] && (
                              <p style={{ color: "red", fontSize: "12px" }}>
                                {this.state.validation["photo"]}
                              </p>
                            )}
                          </Col>
                        </FormGroup>

                        <Col sm={9} style={{ textAlign: "right" }}>
                          <Button
                            color="success"
                            style={{ marginRight: 10 }}
                            onClick={() => this.submitEditForm()}
                          >
                            Submit Form
                          </Button>
                        </Col>
                        {/* </Col> */}
                      </Row>
                    </Form>
                  </Container>
                </CardBody>
              </Card>
            </OffcanvasBody>
          </Offcanvas>
        </div>

        <Modal
          isOpen={deleteModal}
          toggle={() => this.setState({ deleteModal: !this.state.deleteModal })}
        >
          <ModalHeader
            toggle={() =>
              this.setState({ deleteModal: !this.state.deleteModal })
            }
            close={() => this.setState({ deleteModal: false })}
          >
            Delete User
          </ModalHeader>
          <ModalBody>
            Do you want to Delete{" "}
            <strong> {this.state.deleteItem.name} </strong> User from{" "}
            <strong> {this.state.deleteItem.coCode}'s </strong> Organization ?
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                this.handleDeleteOFUsers(
                  this.state.deleteItem,
                  this.state.deleteIndex
                );
                this.setState({ deleteModal: false });
              }}
            >
              Confirm
            </Button>
            <Button
              color="secondary"
              onClick={() => this.setState({ deleteModal: false })}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}
export default Users;
